.hoox-ugc-videos {
    padding: 48px 0;
    background-color: #FAFAFA;
}

.hoox-ugc-videos__header { }

.hoox-ugc-videos__title {
    margin-bottom: 24px;
}

.hoox-title { }

.text-align-center { }

.hoox-ugc-videos__items { }

.hoox-swiper-ugc { }

.swiper { }

.swiper-wrapper { }

.swiper-slide { }

.hoox-ugc-videos__video {
    width: 100%;
    display: grid;
    place-items: center;
    max-height: 400px;
}

.hoox-ugc-videos__video fw-storyblock {
    display: block;
    /*max-width: 255px;*/
    height: 400px;
}

.hoox-swiper-ugc__navigation {
    display: none;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
}

.hoox-swiper-ugc__navigation.visible {
    display: flex;
}

.slider-ugc-prev { }

.hoox-swiper-ugc__pagination {
    position: unset;
    width: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background-color: var(--hoox-color-white);
    border-radius: 32px;
}

.hoox-ugc-videos .swiper-pagination-bullet.swiper-pagination-bullet {
    border: 1px solid var(--hoox-color-slate-secondary);
    background: transparent;
    opacity: 1;
}

.hoox-ugc-videos .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--hoox-color-slate-secondary);
}

.swiper-pagination { }

.hoox-swiper-ugc__pagination,
.slider-ugc-prev,
.slider-ugc-next {
    cursor: pointer;
}

@media (max-width: 768px) {
    .hoox-ugc-videos__video {
        width: 225px;
        margin: 0 auto;
    }
}

@media (min-width: 768px) {
    .hoox-ugc-videos {
        padding: 72px 0;
    }

    .hoox-ugc-videos__title {
        margin-bottom: 32px;
    }

    .hoox-ugc-videos__items {
        max-width: 1092px;
    }
}
